import React, { memo, useMemo, useCallback } from 'react';
import Head from 'next/head';
import groupBy from 'lodash/groupBy';

const PreloadImage = ({ srcSet }) => {
  const preloadImages = useMemo(() => {
    if (!srcSet?.length) {
      return [];
    }

    const groupedSources = Object.values(groupBy(srcSet, ({ width: srcWidth }) => srcWidth || 9999));

    if (groupedSources?.length === 1) {
      return groupedSources;
    }

    const sortedSources = [...groupedSources].sort((groupA, groupB) => {
      const groupAWidth = groupA?.[0]?.width || 9999;
      const groupBWidth = groupB?.[0]?.width || 9999;

      return groupAWidth - groupBWidth;
    });

    return sortedSources?.map((group, index) => {
      let mediaQuery = null;
      const groupWidth = group?.[0]?.width;

      const minWidth = index > 0 ? sortedSources?.[index - 1]?.[0]?.width : null;

      if (minWidth) {
        mediaQuery = `(min-width:${minWidth}px)`;
      }

      if (groupWidth > 0) {
        mediaQuery = mediaQuery ? `${mediaQuery} and (max-width:${groupWidth}px)` : `(max-width:${groupWidth}px)`;
      }

      return group?.map(data => ({ ...data, mediaQuery: mediaQuery ? { media: mediaQuery } : {} }));
    });
  }, [srcSet]);

  const getUniqueKey = useCallback(({ path, type, width }) => {
    const pathParts = path?.split('/');
    const fileName = pathParts?.pop() || pathParts?.pop();

    let key = `${pathParts?.pop()}-${fileName}-${type}`;

    if (width) {
      key = `${key}-${width}`;
    }

    return key;
  }, []);

  if (preloadImages?.length === 0) {
    return null;
  }

  return (
    <Head>
      {preloadImages?.map(widthGroup => (
        <link
          key={getUniqueKey(widthGroup?.[0])}
          rel="preload"
          // eslint-disable-next-line react/no-unknown-property
          imagesrcset={widthGroup?.map(({ path }) => path).join(', ')}
          as="image"
          {...widthGroup?.[0]?.mediaQuery}
        />
      ))}
    </Head>
  );
};

export default memo(PreloadImage);
